<template>
    <section class="careers-category" v-if="categoryData">
        <div class="category__description lockout">
            <div class="category__description__mobile-top">
                <div class="left">
                    <img class="career-icon" :src="categoryData.icon" alt="">
                    <router-link to="/careers">
                        <!-- <img class="back-img" src="img/careers/career-category-back.svg" alt="back to careers"> -->
                        <img class="back-img" src="../assets/careers/career-category-back.svg" alt="back to careers">

                        
                    </router-link>

                    <div class="career-title">{{ categoryData.title }}</div>
                </div>
                <div class="career-subtitle">{{ categoryData.subtitle }}</div>
            </div>
            <div class="category__description__detail" v-html="categoryData.description"></div>
        </div>
        <div class="category__openings lockout">
            <div class="openings">
                <p class="numeric">{{ categoryData.jobs.length }}</p>
                <p class="label">OPENINGS</p>
            </div>
            <div class="dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>
            <ul>
                <li class="category__openings__opening" v-for="(job, i) in categoryData.jobs" :key="job.id">
                    <router-link :to="job.slug" append>
                        <div class="position">{{ job.position }}</div>
                        <div class="details">
                            <div class="type">{{ job.type }}</div>
                            <!-- <div class="posted">Posted: {{ job.date }}</div> -->
                        </div>
                        <DiamondButton></DiamondButton>
                    </router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    import router from '../router'
    import DiamondButton from '../components/buttons/DiamondButton'
    import axios from 'axios'
    //import Meta from '../mixins/Meta.js'

    export default {
        // mixins: [Meta],
        props: ['data'],
        components: {
            DiamondButton
        },
        data() {
            return {
                categoryData: null
            }
        },
        mounted() {
            $(window).scrollTop(0);
            if (this.data) {
                this.setCategoryData();
            }
        },
        methods: {
            setCategoryData() {
                var slug = this.$route.params.category;
                this.categoryData = this.data.positions[slug];
                //console.log('here', this.categoryData);
            }
        },
        watch: {
            data() {
                this.setCategoryData();
            }
        }
    }
</script>